import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse, LoginService } from '../../services/login.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  form: FormGroup;
  submitting = false;
  loginError = '';

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      pswd: ['', Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.loginError = '';
    if (this.form.invalid) {
      return;
    }
    this.submitting = true;
    this.loginService.login(this.form.value).subscribe(
      (resp: IResponse) => {
        this.submitting = false;
        if (resp.success) {
          this.auth.login();
          this.router.navigate(['/dashboard']);
        } else {
          this.loginError = resp.message || 'servererror';
        }
      },
      (error) => {
        this.submitting = false;
        console.error('ERROR', error);
        this.loginError = 'servererror';
      }
    );
  }
}
