import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

export interface IServerResponse {
  success: boolean;
  message: any | any[];
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  baseUrl = 'ws/analytics.php';

  constructor(private http: HttpClient) {}

  getData(appName: string, dateRange: string[]) {
    const url = `${this.baseUrl}?cmd=data&app=${appName}&start=${dateRange[0]}&stop=${dateRange[1]}`;
    return this.http.get(url).pipe(
      map((response: IServerResponse) => {
        return response;
      })
    );
  }

  getTitles(appName: string) {
    const url = `${this.baseUrl}?cmd=titles&app=${appName}`;
    return this.http.get(url).pipe(
      map((response: IServerResponse) => {
        return response;
      })
    );
  }
}
