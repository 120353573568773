<app-admin-header></app-admin-header>
<div class="row mt-2 justify-content-center">
  <div class="col-12 col-md-6">
    <div class="card">
      <h4 class="card-header">Login</h4>
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group mt-2">
            <label for="email">User email</label>
            <input
              type="email"
              id="email"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitting && f.email.errors }"
            />
            <div *ngIf="submitting && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">User Email is required</div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="pswd">Password</label>
            <input
              type="password"
              id="pswd"
              formControlName="pswd"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitting && f.pswd.errors }"
            />
            <div *ngIf="submitting && f.pswd.errors" class="invalid-feedback">
              <div *ngIf="f.pswd.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group mt-2 d-flex justify-content-between">
            <div>
              <div [hidden]="!loginError.length" class="alert alert-danger">
                <span *ngIf="loginError == 'invalidpassword'"> Invalid password </span>
                <span *ngIf="loginError == 'notfound'">Account not found</span>
                <span *ngIf="loginError == 'servererror'">Server error</span>
              </div>
            </div>
            <div>
              <button [disabled]="submitting" class="btn btn-primary">
                <span *ngIf="submitting" class="spinner-border spinner-border-sm mr-1"></span>
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
