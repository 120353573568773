import { Component, Input, OnInit } from '@angular/core';
import { IVideo } from '../../pages/dashboard/dashboard.component';

@Component({
  selector: 'app-videos-table',
  templateUrl: './videos-table.component.html',
  styleUrls: ['./videos-table.component.scss'],
})
export class VideosTableComponent implements OnInit {

  @Input() videosData: IVideo[];

  constructor() {
  }

  ngOnInit(): void {
    console.log('displaying videos table', this.videosData);
  }

}
