import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { AnalyticsService } from '../../services/analytics.service';
import { toDate } from 'date-fns-tz';
import { orderBy } from 'lodash';
import { writeFileXLSX, utils, write } from 'xlsx';
import { UpdateTableService } from '../../services/update-table.service';

export interface IAnalytics {
  id: string;
  date: string | Date;
  app: 'ios' | 'web';
  kol: string;
  video: string;
  title?: string;
  userEmail?: string;
}

export interface IHitCount {
  name: string;
  count: number;
  title?: string;
}

export interface IPlatformCount {
  name: string;
  value: number;
  percent: number | string;
}

export interface IVideo {
  id: string;
  kol: string;
  title: string;
}

interface IVideoItem {
  name: string;
  id: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  loadStatus: 'pending' | 'loaded' | 'empty';
  error: string;
  currentApp: 'RVCC' | 'DVCC' | '' = '';
  dateRange: string[];
  analyticsData: IAnalytics[];
  contentVideos: IVideo[];
  kols: IHitCount[];
  videos: IHitCount[];
  platformCount: IPlatformCount[];
  totalHits: number;

  constructor(private analyticsService: AnalyticsService, private updateTableService: UpdateTableService) {
    this.loadStatus = 'empty';
  }

  ngOnInit(): void {
  }

  private getData() {
    this.loadStatus = 'pending';
    this.analyticsService.getTitles(this.currentApp).subscribe((resp) => {
      if (resp.success) {
        this.contentVideos = resp.message;
        this.analyticsService.getData(this.currentApp, this.dateRange).subscribe((resp) => {
          if (resp.success) {
            this.analyticsData = resp.message.map((item: IAnalytics) => {
              const date = toDate(item.date);
              const parts = item.video.split('_');
              parts.splice(2);
              const videoId = parts.join('_');
              let video = this.contentVideos.find((video) => video.id === videoId);
              const title = video ? video.title : item.video;
              item.title = title || '?';
              return { ...item, date };
            });
            this.calculations();
          } else {
            this.error = 'Server error, please try again later';
          }
        });
      } else {
        console.error('ERROR:', JSON.stringify(resp));
        this.error = 'Server error, please try again later';
      }
      this.loadStatus = 'empty';
    });
  }

  appSelected($event: any) {
    this.currentApp = $event.target.value;
    if (this.currentApp.length && this.dateRange?.length) {
      this.getData();
    }
  }

  dateSelected($event: (Date | undefined)[]) {
    this.dateRange = $event.map((d) => {
      return format(d, 'yyyy-MM-dd');
    });
    if (this.currentApp.length && this.dateRange?.length) {
      this.getData();
    }
  }

  calculations() {
    const videoList = this.extractCategoryItems('video');
    const kols = this.extractCategoryItems('kol');
    const videos:IVideoItem[] = [];
    videoList.forEach((video) => {
      const parts = video.split('_');
      parts.splice(2);
      const id = parts.join('_');
      videos.push({ name: video, id });
    });

    this.kols = [];
    for (let i = 0; i < kols.length; i++) {
      const id = kols[i];
      const items = this.analyticsData.filter((item) => item.kol === id);
      this.kols.push({ name: id, count: items.length });
    }
    this.videos = [];
    for (let i = 0; i < videos.length; i++) {

      const itm = videos[i];
      const items = this.analyticsData.filter((item) => {
        const parts = item.video.split('_');
        parts.splice(2);
        const videoId = parts.join('_');
        return videoId === itm.id;
      });
      let video = this.contentVideos.find((video) => video.id === itm.id);
      const title = video ? video.title : itm.id;
      this.videos.push({ name: itm.name, title: title, count: items.length });
    }
    this.kols = orderBy(this.kols, ['count'], ['desc']);
    this.videos = orderBy(this.videos, ['count'], ['desc']);
    this.kols = this.kols.slice(0, 10);
    this.videos = this.videos.slice(0, 10);

    const iosCount = this.analyticsData.reduce((currentValue: number, item: IAnalytics) => {
      return currentValue + (item.app === 'ios' ? 1 : 0);
    }, 0);
    const webCount = this.analyticsData.reduce((currentValue: number, item: IAnalytics) => {
      return currentValue + (item.app === 'web' ? 1 : 0);
    }, 0);
    this.totalHits = iosCount + webCount;
    this.platformCount = [
      { name: 'iPad App', value: iosCount, percent: iosCount / this.totalHits },
      { name: 'Web site', value: webCount, percent: webCount / this.totalHits },
    ];
    this.loadStatus = 'loaded';
  }

  extractCategoryItems(category: string) {
    return [...new Map(this.analyticsData.map((item) => [item[category], item])).values()].map((item) => item[category]);
  }

  async saveData() {
    const platformData = [...this.platformCount];
    platformData.push({ name: 'total', value: this.totalHits, percent: '' });
    const workbook = utils.book_new();
    const kolSheet = utils.json_to_sheet(this.kols);
    utils.book_append_sheet(workbook, kolSheet, 'KOLs');
    const videosSheet = utils.json_to_sheet(this.videos);
    utils.book_append_sheet(workbook, videosSheet, 'Videos');
    const platformSheet = utils.json_to_sheet(platformData);
    utils.book_append_sheet(workbook, platformSheet, 'Platform');
    const rawData = utils.json_to_sheet(this.analyticsData);
    utils.book_append_sheet(workbook, rawData, 'Raw Data');
    let dateExpr = this.dateRange.join('_');
    const filename = `${this.currentApp}_${dateExpr}_data.xlsx`;
    try {
      const fileHandle = await this.getNewFileHandle(filename);
      fileHandle && this.writeFile(fileHandle, workbook);
    } catch (e) {
      writeFileXLSX(workbook, `${this.currentApp}_${dateExpr}_data.xlsx`);
    }
  }

  async getNewFileHandle(filename) {
    const options = {
      suggestedName: filename,
      types: [
        {
          description: 'Excel 2007+ (XLSX)',
          accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
        },
      ],
    };
    // @ts-ignore
    const fileHandle = await window.showSaveFilePicker(options);
    return fileHandle;
  }

  async writeFile(fileHandle: any, contents: any) {
    const wstream = await fileHandle.createWritable();
    wstream.write(write(contents, { bookType: 'xlsx', type: 'buffer' }));
    wstream.close();
  }

  updateTable(app: 'RVCC' | 'DVCC' | '') {
    switch (app) {
      case 'RVCC':
        this.updateTableService.updateTable(app);
        break;
    }
  }
}
