import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string;

  constructor(private cookieService: CookieService) {}

  login(): void {
    const expDate = new Date();
    expDate.setTime(expDate.getTime() + 60 * 60 * 24 * 1000);
    const host = window.location.host;
    // TODO: update with production domain name
    if (host.indexOf('tbd') >= 0) {
      this.cookieService.set('loggedIn', 'true', expDate, '', host, true, 'Strict');
    } else {
      this.cookieService.set('loggedIn', 'true', expDate, '', host, false, 'Lax');
    }
    this.isLoggedIn = true;
  }

  logout(): void {
    this.isLoggedIn = false;
    const host = window.location.host;
    // TODO: update with production domain name
    if (host.indexOf('tbd') >= 0) {
      this.cookieService.set('loggedIn', 'false', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '', host, true, 'Strict');
      this.cookieService.delete('loggedIn', '', host, true, 'Strict');
    } else {
      this.cookieService.set('loggedIn', 'false', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '', host, false, 'Lax');
      this.cookieService.delete('loggedIn', 'true', host, false, 'Lax');
    }
  }

  checkLogin(): boolean {
    return this.cookieService.get('loggedIn') === 'true';
  }
}
