<div class="row mt-2">
  <div class="col">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th class="date">Date</th>
          <th>App</th>
          <th>KOL</th>
          <th>Video</th>
          <th>Title</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of analyticsData">
          <td>{{ item.date | date }}</td>
          <td>{{ item.app }}</td>
          <td>{{ item.kol }}</td>
          <td>{{ item.video }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.userEmail || '?' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
