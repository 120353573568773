import { Component, Input, OnInit } from '@angular/core';
import { IHitCount } from '../../pages/dashboard/dashboard.component';

@Component({
  selector: 'app-top-results',
  templateUrl: './top-results.component.html',
  styleUrls: ['./top-results.component.scss'],
})
export class TopResultsComponent implements OnInit {
  @Input() type: 'kols' | 'videos';
  @Input() title: string;
  @Input() hitsList: IHitCount[];

  constructor() {}

  ngOnInit(): void {}
}
