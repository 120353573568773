<app-admin-header></app-admin-header>
<div class="content">
  <div class="row mt-4">
    <div class="col-3">
      <h2>Dashboard</h2>
    </div>
    <div class="col-3">
      <select class="form-select" aria-label="Default select example" (change)="appSelected($event)">
        <option value="">Select an AZ App</option>
        <option [selected] value="RVCC">RVCC - Symbicort</option>
      </select>
    </div>
    <div class="col">
      <input type="text" class="form-control" placeholder="Select a date range" bsDaterangepicker (bsValueChange)="dateSelected($event)" />
    </div>
    <div class="col">
      <button class="btn btn-primary" [disabled]="loadStatus !== 'loaded'" (click)="saveData()">Save to Excel</button>
    </div>
    <div class="col">
      <button class="btn btn-secondary" [disabled]="!currentApp" (click)="updateTable(currentApp)">Update Table</button>
    </div>
  </div>
  <div *ngIf="error" class="alert alert-warning" role="alert">
    {{ error }}
  </div>
  <div *ngIf="loadStatus === 'pending'">
    <p>Loading…</p>
  </div>
  <div *ngIf="loadStatus === 'loaded'">
    <tabset>
      <tab heading="Top 10" id="tab1">
        <div class="row">
          <div class="col-3">
            <app-top-results [hitsList]="kols" [title]="'KOLs'" [type]="'kols'"></app-top-results>
          </div>
          <div class="col-9">
            <app-top-results [hitsList]="videos" [title]="'Videos'" [type]="'videos'"></app-top-results>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <ngx-charts-advanced-pie-chart
              [results]="platformCount"
              [gradient]="false"
              [animations]="false"
            ></ngx-charts-advanced-pie-chart>
          </div>
          <div class="col-4">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Platform</th>
                  <th>Count</th>
                  <th>Percent</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of platformCount">
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.percent | percent }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </tab>
      <tab heading="Raw Data" id="tab2">
        <div class="data-display" *ngIf="loadStatus === 'loaded'">
          <app-analytics-table [analyticsData]="analyticsData"></app-analytics-table>
        </div>
      </tab>
      <tab heading="Videos" id="tab3">
        <div class="data-display" *ngIf="loadStatus === 'loaded'">
          <app-videos-table [videosData]='contentVideos'></app-videos-table>
        </div>
      </tab>
    </tabset>
  </div>
</div>
