<table class="table table-sm table-bordered">
  <thead>
    <tr>
      <th>{{ title }}</th>
      <th>Count</th>
      <th *ngIf="type === 'videos'">Title</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of hitsList">
      <td>{{ item.name }}</td>
      <td>{{ item.count }}</td>
      <td *ngIf="type === 'videos'">{{ item.title }}</td>
    </tr>
  </tbody>
</table>
