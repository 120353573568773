<div class="row mt-2">
  <div class="col">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of videosData">
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
