import { Component, Input, OnInit } from '@angular/core';
import { IAnalytics } from '../../pages/dashboard/dashboard.component';

@Component({
  selector: 'app-analytics-table',
  templateUrl: './analytics-table.component.html',
  styleUrls: ['./analytics-table.component.scss'],
})
export class AnalyticsTableComponent implements OnInit {
  @Input() analyticsData: IAnalytics[];

  constructor() {}

  ngOnInit(): void {}
}
