import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ICredentials {
  email: string;
  pswd: string;
}

export interface IResponse {
  success: boolean;
  message: any;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  wsUrl = `/ws/login.php`;

  constructor(private http: HttpClient) {}
  login(credentials: ICredentials) {
    const url = `${this.wsUrl}?cmd=applogin&email=${credentials.email}&pswd=${credentials.pswd}`;
    return this.http.get<IResponse>(url);
  }
}
