import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of } from 'rxjs';

interface IQuestion {
  kols: string[];
  qid: string;
  question: string;
}
interface ITopic {
  id: string;
  questions: IQuestion[];
}

interface IData {
  id: string;
  kol: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class UpdateTableService {
  private app: string;

  private appDomain: string;
  constructor(private http: HttpClient) {}

  updateTable(app: string) {
    this.app = app;
    const host = window.location.hostname;
    switch (this.app) {
      case 'RVCC':
        switch (host) {
          case 'az-admin':
            this.appDomain = 'http://rvcc-app';
            break;
          case 'admin.adventmedia.net':
            this.appDomain = 'https://respvirtualclinicconversations.ca';
            break;
        }
    }

    this.getTableData(app)
      .pipe(
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            console.error(`Error: ${error.error.message}`);
          } else {
            console.error(`Error: ${error.message}`);
          }
          return of([]);
        })
      )
      .subscribe((resp: any) => {
        this.processData(resp.MENU.TOPICS);
      });
  }

  private processData(data: ITopic[]) {
    let questions = [];
    data.forEach((topic) => {
      questions = [...questions, ...topic.questions];
    });
    let items: IData[] = [];
    questions.forEach((q) => {
      q.kols.forEach((k) => {
        const id = `${q.qid}_${k}`;
        items.push({ id, kol: k, title: q.question });
      });
    });
    this.sendTableData(items).subscribe((resp) => {});
  }
  private getTableData(app: string) {
    let url: string;
    switch (app) {
      case 'RVCC':
        url = `${this.appDomain}/assets/i18n/en.json`;
        break;
    }
    return this.http.get(url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  private sendTableData(data: IData[]) {
    const url = `/ws/tableData.php?cmd=update&app=${this.app}`;
    return this.http.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
